import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { LanguageService, RoutingService } from '@spartacus/core';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { SharedService } from 'src/app/shared/services/shared.service';
import { DataLayerService } from '../../shared/services/data-layer.service';
import { CustomInfluenzaService } from '../custom-influenza.service';

@Component({
  selector: 'app-flu-checkout',
  templateUrl: './flu-checkout.component.html',
  styleUrls: ['./flu-checkout.component.scss'],
})
export class FluCheckoutComponent implements OnInit {
  paymentsArray: any = [];
  chosenPaymentMethod = 1;
  screenwidth: any;
  header: any = 'Checkout';
  stdCart = false;
  cartDetails: any[];
  activeSeasonId: any;
  languageUsed: string;
  userId: any;
  activeSeasonSubscriber: any;
  constructor(
    private readonly influenzaService: CustomInfluenzaService,
    private readonly router: RoutingService,
    private readonly sharedService: SharedService,
    private readonly languageService: LanguageService,
    private readonly ref: ChangeDetectorRef,
    private readonly user: UserAccountFacade,
    private readonly dataLayerService: DataLayerService
  ) {
    this.screenwidth = window.screen.width;
  }

  ngOnInit(): void {
    this.user.get().subscribe((data: any) => {
      if (data) {
        this.userId = data?.userName;
        this.influenzaService.getActiveSeasonsFromDb(this.userId);
      }
    });
    this.activeSeasonSubscriber = this.influenzaService.activeSeaons.subscribe(
      (response) => {
        if (response) {
          this.activeSeasonId = response?.categoryData?.[0]?.code;
          if (this.activeSeasonId) {
            this.activeSeasonId = this.activeSeasonId.replace('-', '/');
          }
          this.activeSeasonSubscriber?.unsubscribe();
          this.ref.detectChanges();
        }
      }
    );
    this.languageService.getActive().subscribe((langRes) => {
      this.languageUsed = langRes;
      this.ref.detectChanges();
    });
    this.influenzaService.cartType = 'I';
    this.paymentsArray = [
      { name: 'Bill Me Later', code: 1 },
      { name: 'Pay by Card', code: 2 },
      { name: 'Manage Payment Methods', code: 3 },
    ];
    this.chosenPaymentMethod = 1;

    //Redirect to Flu dashboard if an order has been placed
    const fluOrdered = sessionStorage.getItem('flu-has-order');
    if ((fluOrdered && fluOrdered == 'yes') || !fluOrdered) {
    }
  }
  backToDashBoard() {
    this.influenzaService.skipDelivery$.subscribe((res: any) => {
      if (res) {
        this.router.goByUrl('/influenza/cart');
      } else {
        this.router.goByUrl('/influenza/schedule-to-ship');
      }
      this.ref.detectChanges();
    });
  }
  OnFluPrint() {
    window.print();
  }

  downloadExcel() {
    this.influenzaService.downloadXls(
      this.influenzaService.dataForExcel,
      'Checkout'
    );
    const excelDataLayer = {
      localizedDownloadedMedia: {
        name: 'Checkout.xlsx',
      },
      downloadedMediaType: 'xlsx',
    };
    this.dataLayerService.downloadDataLayer(excelDataLayer);
  }
}
