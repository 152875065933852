import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CsAgentAuthService } from '@spartacus/asm/root';
import { AuthService } from '@spartacus/core';
import { ICON_TYPE } from '@spartacus/storefront';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { Observable, Subscription } from 'rxjs';
import * as _ from 'underscore';
import { CustomProfileAndPreferencesService } from '../custom-profile-and-preferences.service';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserComponent implements OnInit, OnDestroy {
  nameEditIsNotClicked = true;
  phoneEditIsNotClicked = true;
  roleEditIsNotClicked = true;
  passwordEditIsNotClicked = true;
  passRecEditIsNotClicked = true;
  emailEditIsNotClicked = true;
  commConfmEditIsNotClicked = true;
  smsCommConfmEditIsNotClicked = true;
  nameForm: FormGroup;
  phoneForm: FormGroup;
  passwordForm: FormGroup;
  emailForm: FormGroup;
  alternateEmailForm: FormGroup;
  chosenRoleofUser = '';
  passwordYetToMatch: boolean;
  passwordIsNotValid = true;
  passwordConfirmStatus = '';
  chosenQueForPassRecovery = '';
  ansForQuePassRecovery = null;
  loginUserInfo: any;
  emailCommunicationStatus = '';
  smsCommunicationStatus = '';
  emailConsent = '';
  smsConsent = '';
  isAsmEnabled = false;
  roleOfUserArray = [];
  passRecQuesArray = [];
  displayRoleofUser: any;
  displayQueForPassRecovery: any;
  displayPhone: any;
  showAlternateEmailField = false;
  currentPasswordIsNotValid = true;
  cPassError: any = '';
  emailRegex = '';
  userDtaaSubscriber: any;
  userRolesSubscriber: any;
  userDtaUpdateSubscriber: any;
  passQueSubscriber: any;
  userDataUpdateSubscriber2: any;
  csAgentAuthSubscription: Subscription;
  accountIdSubscription: Subscription;
  userLoggedInSub: Subscription;
  checked: boolean = false;
  type: string = 'password';
  iconTypes = ICON_TYPE;
  isLoggedIn: Observable<boolean> = this.authService.isUserLoggedIn();
  isAdmin: boolean;

  constructor(
    private readonly pfService: CustomProfileAndPreferencesService,
    private readonly ref: ChangeDetectorRef,
    private readonly csAgentAuthService: CsAgentAuthService,
    private readonly authService: AuthService,
    private readonly userAccount: UserAccountFacade
  ) {}
  ngOnInit() {
    this.formInitialisation();
    this.getUserData();
    this.validatePassword();
    this.csAgentAuthSubscription = this.csAgentAuthService
      .isCustomerEmulated()
      .subscribe((res) => {
        this.isAsmEnabled = res ? res : false;
      });
    this.userAccount.get().subscribe((res: any) => {
      this.isAdmin = res?.isAdmin;
      this.ref.detectChanges();
    });
  }

  ngOnDestroy() {
    this.userDtaaSubscriber?.unsubscribe();
    this.userRolesSubscriber?.unsubscribe();
    this.userDtaUpdateSubscriber?.unsubscribe();
    this.passQueSubscriber?.unsubscribe();
    this.userDataUpdateSubscriber2?.unsubscribe();
    this.userLoggedInSub?.unsubscribe();
  }

  //  ==================================== //Password Validation=================================

  validatePassword() {
    this.passwordForm.controls['newPassword'].valueChanges.subscribe(
      (value) => {
        if (value !== null && value !== undefined && value.length > 0) {
          this.regexValidation('newPass', value);
        }
      }
    );
    this.passwordForm.controls['currentPassword'].valueChanges.subscribe(
      (value) => {
        if (value !== null && value !== undefined && value.length > 0) {
          this.regexValidation('currentPass', value);
        }
      }
    );
  }
  regexValidation(pass, value) {
    this.cPassError = '';
    const checkLowerCaseLetter = new RegExp('^(?=.*?[a-z]).{1,15}$');
    const checkUpperCaseLetter = new RegExp('^(?=.*?[A-Z]).{1,15}$');
    const checkNumberRegex = new RegExp('^(?=.*?[0-9]).{1,15}$');
    const allowedSpecialCharRegex = new RegExp('^(?=.*?[*$%!()&^]).{1,15}$');
    const allSymbolRegex = /[ `@#_+\-=\[\]{};':"\\|,.<>\/?~]/;

    let counter = 0;
    const checkLowerCaseLetterBoolean = checkLowerCaseLetter.test(value);
    const checkUpperCaseLetterBoolean = checkUpperCaseLetter.test(value);
    const checkNumberRegexBoolean = checkNumberRegex.test(value);
    const allowedSpecialCharRegexBoolean = allowedSpecialCharRegex.test(value);
    const minPasswordLength = 8;
    const maxPasswordLength = 15;
    const validPasswordCriteria = 3;
    if (checkLowerCaseLetterBoolean === true) {
      counter += 1;
    }
    if (checkUpperCaseLetterBoolean === true) {
      counter += 1;
    }
    if (checkNumberRegexBoolean === true) {
      counter += 1;
    }
    if (allowedSpecialCharRegexBoolean === true) {
      counter += 1;
    }

    if (allSymbolRegex.test(value) === true) {
      counter = 0;
    }
    this.finalPassCheck(
      pass,
      counter,
      validPasswordCriteria,
      value,
      minPasswordLength,
      maxPasswordLength
    );
  }
  finalPassCheck(
    password,
    counterNum,
    validPassCri,
    val,
    minPassLength,
    maxPassLength
  ) {
    if (password === 'newPass') {
      if (
        counterNum >= validPassCri &&
        val.length >= minPassLength &&
        val.length <= maxPassLength
      ) {
        this.passwordIsNotValid = false;
      } else {
        this.passwordIsNotValid = true;
      }
    } else {
      if (val.length >= minPassLength && val.length <= maxPassLength) {
        this.currentPasswordIsNotValid = false;
      } else {
        this.currentPasswordIsNotValid = true;
      }
    }
  }

  // =======================All forms Initilaisation=============================
  formInitialisation() {
    const maxlengthChar = 256;
    const maxlengthTwelve = 12;
    this.nameForm = new FormGroup({
      actualFirstName: new FormControl('', [
        Validators.required,
        Validators.maxLength(maxlengthChar),
      ]),
      actualLastName: new FormControl('', [
        Validators.required,
        Validators.maxLength(maxlengthChar),
      ]),
    });
    this.passwordForm = new FormGroup({
      currentPassword: new FormControl('', Validators.required),
      newPassword: new FormControl('', Validators.required),
      confirmNewPassword: new FormControl('', Validators.required),
    });
    this.emailForm = new FormGroup({
      emailId: new FormControl('', [
        Validators.required,
        Validators.email,

        Validators.pattern(
          '^([A-Za-z0-9\\._-]+)@([A-Za-z0-9\\._-]+)\\.([A-Za-z\\.]{2,8})(\\.[A-Za-z\\.]{2,8})?$'
        ),
      ]),
      confirmEmailId: new FormControl('', Validators.required),
    });
    this.alternateEmailForm = new FormGroup({
      alternateEmailId: new FormControl('', [
        Validators.required,
        Validators.email,
        Validators.pattern(
          '^([A-Za-z0-9\\._-]+)@([A-Za-z0-9\\._-]+)\\.([A-Za-z\\.]{2,8})(\\.[A-Za-z\\.]{2,8})?$'
        ),
      ]),
      confirmAlternateEmailId: new FormControl('', Validators.required),
    });
    this.phoneForm = new FormGroup({
      actualPhone: new FormControl('', [
        Validators.required,
        Validators.pattern('^([0-9]{10,10})$'),
        Validators.maxLength(maxlengthTwelve),
      ]),
    });
  }

  // =====================================Api call methods===============================
  getUserData() {
    this.pfService.getUserDataFromDb(null);
    this.userDtaaSubscriber = this.pfService.userData.subscribe((userData) => {
      this.loginUserInfo = userData?.res;
      if (this.loginUserInfo) {
        if (userData.id === null) {
          this.setAllFormField();
          this.userRoleGetMethod();
          this.getPassWordRecQues();
          this.setAlternateEmailForm();
        } else {
          if (userData.id === 'name') {
            this.setNameField();
          } else if (userData.id === 'role') {
            this.userRoleGetMethod();
          } else if (userData.id === 'password') {
            this.setPasswordField();
          } else if (userData.id === 'passRec') {
            this.getPassWordRecQues();
            this.setPassRecField();
          } else if (userData.id === 'email') {
            this.setEmailForm();
          } else if (userData.id === 'emailConsent') {
            this.setAlternateEmailForm();
            this.setEmailConsent();
          } else if (userData.id === 'smsConsent') {
            this.setSmsConsent();
          } else {
            // No Change
          }
        }
      }
    });
  }
  // ===============================set field values=======================
  setAllFormField() {
    this.setNameField();
    this.setPasswordField();
    this.setPassRecField();
    this.setEmailForm();
    this.setEmailConsent();
    this.setSmsConsent();
    this.setPhoneField();
  }

  // ===============================Name field functions=======================
  setNameField() {
    this.nameForm.reset();
    let firstName = '';
    let lastName = '';

    if (
      this.loginUserInfo.firstName !== undefined &&
      this.loginUserInfo.firstName !== null
    ) {
      firstName = this.loginUserInfo.firstName;
    }
    if (
      this.loginUserInfo.lastName !== undefined &&
      this.loginUserInfo.lastName !== null
    ) {
      lastName = this.loginUserInfo.lastName;
    }
    this.nameForm.setValue({
      actualFirstName: firstName,
      actualLastName: lastName,
    });
    this.ref.detectChanges();
  }
  editNameField() {
    this.nameEditIsNotClicked = false;
  }
  updateNameField() {
    this.nameEditIsNotClicked = true;
    const params = {
      firstName: this.nameForm.controls['actualFirstName'].value,
      lastName: this.nameForm.controls['actualLastName'].value,
      profileUpdateType: 'NAME',
    };
    this.updateAllUserDataFields(params, 'name');
  }
  cancelNameUpdate() {
    this.nameEditIsNotClicked = true;
    this.setNameField();
  }
  // -------Phone number
  setPhoneField() {
    this.phoneForm.reset();
    let phoneNumber = '';
    if (this.loginUserInfo?.phoneNumber) {
      phoneNumber = this.loginUserInfo.phoneNumber;
    }
    this.phoneForm.setValue({
      actualPhone: phoneNumber,
    });
    this.ref.detectChanges();
  }
  editPhoneField() {
    this.phoneEditIsNotClicked = false;
  }

  updatePhoneField() {
    this.phoneEditIsNotClicked = true;
    const params = {
      phoneNumber: this.phoneForm.controls['actualPhone'].value,
      profileUpdateType: 'PHONE',
    };
    this.updateAllUserDataFields(params, 'phoneNumber');
  }

  cancelPhoneUpdate() {
    this.phoneEditIsNotClicked = true;
    this.setPhoneField();
  }

  // ===============================Role field functions=======================

  userRoleGetMethod() {
    this.pfService.getUserRoles();
    this.userRolesSubscriber = this.pfService.userRoles.subscribe((data) => {
      if (data) {
        const roles = _.filter(data, function (role) {
          return role.code != 'OTHER' && role.code != '62';
        });
        this.roleOfUserArray = roles;
        this.userRoleMapping();
      }
      this.userRolesSubscriber?.unsubscribe();
    });
  }

  userRoleMapping() {
    this.roleOfUserArray.forEach((res) => {
      if (
        this.loginUserInfo?.role !== undefined &&
        this.loginUserInfo?.role !== null &&
        res.name === this.loginUserInfo.role
      ) {
        this.chosenRoleofUser = res.code;
        this.displayRoleofUser = res.name;
        this.ref.detectChanges();
      }
    });
  }

  editRoleField() {
    this.roleEditIsNotClicked = false;
  }
  updateRoleField() {
    this.roleEditIsNotClicked = true;
    this.roleOfUserArray.forEach((res) => {
      if (res.code === this.chosenRoleofUser) {
        this.displayRoleofUser = res.name;
        this.ref.detectChanges();
      }
    });
    const params = {
      role: this.chosenRoleofUser,
      profileUpdateType: 'ROLE',
    };
    this.updateAllUserDataFields(params, 'role');
  }
  cancelRoleUpdate() {
    this.roleEditIsNotClicked = true;
    this.userRoleMapping();
  }
  //================================Password field funcitons===========================

  setPasswordField() {
    this.passwordForm.reset();
    this.passwordForm.setValue({
      currentPassword: '********',
      newPassword: '',
      confirmNewPassword: '',
    });
  }
  editPasswordField() {
    this.passwordEditIsNotClicked = false;
    this.passwordForm.controls['currentPassword'].setValue('');
  }
  cancelPasswordUpdate() {
    this.passwordEditIsNotClicked = true;
    this.cPassError = '';
    this.setPasswordField();
  }
  updatePasswordField() {
    const params = {
      currentPassword: this.passwordForm.controls['currentPassword'].value,
      password: this.passwordForm.controls['newPassword'].value,
      confirmPassword: this.passwordForm.controls['confirmNewPassword'].value,
      profileUpdateType: 'PASSWORD',
    };
    this.pfService.updateUserData(params);
    this.userDtaUpdateSubscriber = this.pfService.userDataUpdate.subscribe(
      (res) => {
        if (res === true) {
          this.pfService.getUserDataFromDb('password');
          this.cPassError = '';
          this.passwordEditIsNotClicked = true;
          this.ref.detectChanges();
        } else {
          const errFromDb = res;
          this.cPassError = errFromDb.error.errors[0].message;
          this.passwordForm.reset();
          this.passwordForm.setValue({
            currentPassword: '********',
            newPassword: '',
            confirmNewPassword: '',
          });
        }
        this.userDtaUpdateSubscriber?.unsubscribe();
      }
    );
  }

  // ===============================PassRec==========================================

  getPassWordRecQues() {
    this.pfService.getHintQuestions();
    this.passQueSubscriber = this.pfService.passQues.subscribe((data) => {
      if (data) {
        this.passRecQuesArray = data;
        this.passRecQueMapping();
        this.ref.detectChanges();
      }
      this.passQueSubscriber?.unsubscribe();
    });
  }

  passRecQueMapping() {
    this.passRecQuesArray.forEach((res) => {
      if (
        this.loginUserInfo.passwordQuestion !== undefined &&
        this.loginUserInfo.passwordQuestion !== null &&
        res.name === this.loginUserInfo.passwordQuestion
      ) {
        this.chosenQueForPassRecovery = res.code;
      }
    });
  }

  setPassRecField() {
    if (
      this.loginUserInfo.passwordQuestion !== undefined &&
      this.loginUserInfo.passwordAnswer !== undefined
    ) {
      this.displayQueForPassRecovery = this.loginUserInfo.passwordQuestion;
      this.ansForQuePassRecovery = this.loginUserInfo.passwordAnswer;
    } else {
      this.displayQueForPassRecovery = '';
      this.ansForQuePassRecovery = null;
    }
    this.ref.detectChanges();
  }
  editPassRecField() {
    this.passRecEditIsNotClicked = false;
  }
  cancelPassRecUpdate() {
    this.passRecEditIsNotClicked = true;
    this.setPassRecField();
    this.passRecQueMapping();
  }
  updatePassRecQues() {
    const param = {
      profileUpdateType: 'PASSWORD_RECOVERY',
      passwordAnswer: this.ansForQuePassRecovery,
      passwordQuestion: this.chosenQueForPassRecovery,
    };

    this.updateAllUserDataFields(param, 'passRec');
    this.passRecEditIsNotClicked = true;
    this.getUserData();
    this.setPassRecField();
    this.passRecQueMapping();
  }

  // ===========================================Email Functions==============================

  setEmailForm() {
    this.emailForm.reset();
    this.emailForm.setValue({
      emailId: this.loginUserInfo.email,
      confirmEmailId: '',
    });
    this.ref.detectChanges();
  }

  cancelEmailUpdate() {
    this.emailEditIsNotClicked = true;
    this.setEmailForm();
  }
  updateEmailField() {
    const params = {
      email: this.emailForm.controls['emailId'].value,
      confirmEmail: this.emailForm.controls['confirmEmailId'].value,
      profileUpdateType: 'EMAIL',
    };
    this.updateAllUserDataFields(params, 'email');
    this.emailEditIsNotClicked = true;
  }
  editEmailField() {
    this.emailEditIsNotClicked = false;
  }

  // ===========================================Email communication=================
  setEmailConsent() {
    if (
      this.loginUserInfo?.emailConsent &&
      this.loginUserInfo?.emailConsent !== undefined &&
      this.loginUserInfo?.emailConsent !== null
    ) {
      this.emailCommunicationStatus = 'Yes';
      this.emailConsent = 'true';
    } else {
      this.emailCommunicationStatus = 'No';
      this.emailConsent = 'false';
    }
    this.ref.detectChanges();
  }
  setAlternateEmailForm() {
    this.alternateEmailForm.reset();
    let alternateEmail = '';
    if (
      this.loginUserInfo?.alternateEmail !== null &&
      this.loginUserInfo?.alternateEmail !== undefined
    ) {
      alternateEmail = this.loginUserInfo.alternateEmail;
    }
    this.alternateEmailForm.setValue({
      alternateEmailId: alternateEmail,
      confirmAlternateEmailId: '',
    });
  }
  editCommField() {
    this.commConfmEditIsNotClicked = false;
  }
  cancelCommuUpdate() {
    this.commConfmEditIsNotClicked = true;
    this.emailConsent = this.loginUserInfo.emailConsent.toString();
    this.smsConsent = this.loginUserInfo.smsConsent.toString();
  }
  cancelEmailConsentUpdate() {
    this.setAlternateEmailForm();
    this.showAlternateEmailField = false;
  }

  addAlternateEmail() {
    this.showAlternateEmailField = true;
  }
  deleteAlternateEmail() {
    const params = {
      alternateEmail: '',
      confirmAlternateEmail: '',
      emailConsent: this.loginUserInfo.emailConsent,
      profileUpdateType: 'EMAIL_CONSENT',
    };
    this.updateAllUserDataFields(params, 'emailConsent');
    this.showAlternateEmailField = false;
  }

  updateEmailCommunField() {
    this.showAlternateEmailField = false;
    if (this.emailConsent === 'true') {
      this.emailCommunicationStatus = 'Yes';
    } else {
      this.emailCommunicationStatus = 'No';
    }
    if (this.smsConsent === 'true') {
      this.smsCommunicationStatus = 'Yes';
    } else {
      this.smsCommunicationStatus = 'No';
    }
    const params = {
      alternateEmail:
        this.alternateEmailForm.controls['alternateEmailId'].value,
      confirmAlternateEmail:
        this.alternateEmailForm.controls['confirmAlternateEmailId'].value,
      emailConsent: this.emailConsent,
      smsConsent: this.smsConsent,
      profileUpdateType: 'EMAIL_CONSENT',
    };
    this.updateAllUserDataFields(params, 'emailConsent');
    this.commConfmEditIsNotClicked = true;
  }

  // SMS
  setSmsConsent() {
    if (
      this.loginUserInfo?.smsConsent &&
      this.loginUserInfo?.smsConsent !== undefined &&
      this.loginUserInfo?.smsConsent !== null
    ) {
      this.smsCommunicationStatus = 'Yes';
      this.smsConsent = 'true';
    } else {
      this.smsCommunicationStatus = 'No';
      this.smsConsent = 'false';
    }
    this.ref.detectChanges();
  }

  // ========================================UpdateApi==============================

  updateAllUserDataFields(params, id) {
    this.userLoggedInSub = this.isLoggedIn.subscribe((resLoggedIn) => {
      if (resLoggedIn) {
        this.pfService.updateUserData(params);
        this.userDataUpdateSubscriber2 =
          this.pfService.userDataUpdate.subscribe(
            (res) => {
              if (res) {
                this.pfService.getUserDataFromDb(id);
              }
              this.userDataUpdateSubscriber2?.unsubscribe();
            });
      }
    });
  }

  togglePassField(event) {
    if (event.target.checked) {
      this.type = 'text';
      this.checked = true;
    } else {
      this.type = 'password';
      this.checked = false;
    }
  }
}
